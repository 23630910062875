<template>
    <div class="content-main">
        <div class="content-header">
            <h1>每日一卡</h1>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="content-nav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>每日一卡</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--title-->
        <div class="article-title">
            <h2>{{setData.title}}</h2>
        </div>
        <div>
            <el-divider></el-divider>
        </div>

        <!--内容-->
        <div class="article-main">
            <div class="article-notice">
                ※如果卡牌图片没有切换，请刷新页面。<br>
                ※如果本站网络连接情况不佳，请不要刷新页面，并在一段时间（15至20分钟左右）后再次尝试。<br>
                （短时间内反复刷新页面可能会导致服务器崩溃。）<br>
                ※图片为开发中的产品。可能与最终发售的商品有不同的部分。<br>
            </div>
            <!--编辑图1-->
            <div class="article-top-img">
                <router-link to="">
                    <el-image
                            :src="productData.img"
                            v-if="productData.img"
                    ></el-image>
                </router-link>
            </div>
            <!--卡牌名称-->
            <div class="m-title article-card-name">
                <span v-for="item in selectData" :key="item.id + 'name'">{{item.card_name}} <br></span>

            </div>
            <!--卡牌列表-->
            <div class="article-card-list">
                <el-image
                        style="width: 25%"
                        v-for="item in selectData" :key="item"
                        :src="item.img"
                >
                </el-image>
            </div>
            <!--总览跳转-->
            <div class="article-card-link m-a-none">
                <router-link to="/index/dayCardList"><i class="el-icon-caret-right"></i>每日一卡总览</router-link>
            </div>
            <!--版权信息-->
            <div class="card-version">
                <div>关于使用官方网站上的卡牌图片</div>
                <p>《卡片战斗先导者》的官方网站（<a target="_blank" href="https://cf-vanguard.com.cn">https://cf-vanguard.com.cn</a>）上的卡牌图片可以在互联网上转载，但必须遵守以下准则。遵守准则使用时，无需联系我们申请许可。请充分利用网站资源，与其他斗士展开交流吧！
                    （2022年5月31日 修订）
                </p>
                <p>
                    <a target="_blank" href="https://bushiroad.oss-cn-beijing.aliyuncs.com/index/4wjxa4djXooXynYDCHiTVOlUpAwrxIXn1653893779.pdf">
                        <img src="https://bushiroad.oss-cn-beijing.aliyuncs.com/index/m1yeFez72XiIg18IG0xAj8pNjRhRn3951653884281.jpg">
                    </a>
                </p>
                <p>
                    如果关于本准则有任何疑问，敬请通过<a href="mailto:enquiry@cf-vanguard.com.cn">enquiry@cf-vanguard.com.cn</a>咨询我们。本准则的内容发生变更时，恕不另行通知。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail",
        data() {
            return {
                setData: {
                    product_id:"",
                    card_ids:"",
                    title:"",
                },
                selectData:[],
                productData:{}
            }
        },
        methods: {
            getData() {
                this.$axios.post("site/siteDayCards/getDayCards",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.setData = res.data.data.setData;
                            this.selectData = res.data.data.selectData;
                            this.productData = res.data.data.productInfo;
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            }
        },
        created() {
            this.getData();
        }
    }
</script>

<style scoped>
    .content-main{
        text-align: center;
        background-color: #ffffff;
    }
    .content-nav{
        width: 60%;
        margin: 0 auto;
    }
    .article-title{
        color: #434343;
    }
    .article-main{
        width: 60%;
        margin: 0 auto;
    }
    .article-main > div{
        margin-top: 30px;
    }
    .article-notice{
        color: #434343;
        font-weight: normal;
        width: 70%;
        text-align: center;
        margin: 0 auto;
        line-height: 25px;
        font-size: 15px;
    }
    .article-top-img{
        width: 80%;
        margin: 0 auto;
    }
    .article-card-name{
        width: 80%;
        font-size: 18px;
        line-height: 30px;
    }
    .article-card-link > a{
        display: block;
        height: 68px;
        width: 386px;
        text-decoration: none;
        margin: 0 auto;
        border: 1px solid #404040;
        line-height: 68px;
        letter-spacing: 2px;
        font-size: 19px;
        font-weight: bold;
    }
    .article-card-link > a:link{
        color: #636b6f;
    }
    .card-version{
        padding: 50px 0;
        text-align: left;
        letter-spacing: 2px;
        line-height: 30px;
    }
    .card-version > div:first-child{
        border-left: 6px solid red;
        height: 50px;
        line-height: 50px;
        font-size: 25px;
        font-weight: bold;
        text-indent:15px;
    }
</style>
